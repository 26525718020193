<template>
  <div class="projectmenu">
    <div class="container-fixed">
      <Navigation/>
      <div class="mobile-proj-menu-definition">
        <div class="proj-menu-definition animate__animated animate__fadeInRight">
          <h2>What We Did</h2>
          <div class="divider">
            <hr>
          </div>
          <div class="content animate__animated animate__fadeInRight animate_delay-2s">
            텍스트리는 LG, 삼성, 두산, 현대, 지멘스, 등과 같은 글로벌 기업이 선택한 신뢰할 수 있는 기술 컨텐츠 파트너입니다. 우리는 업계 최고가 되기 위해 계속해서 서비스를 발전시키고 우리 자신에 도전하고 있습니다.
            <br/>
            <br/>
            고객사의 즉각적이고 장기적인 요구 사항을 파악하고 비즈니스 요구 사항에 맞는 계획을 개발하기 위해 노력하여 기술문서 제작 및 관리에 대한 솔루션을 보유하고 있다는 확신을 드립니다.
          </div>
        </div>
      </div>
      <div id="projects" class="proj-box">
        <ul class="proj-nav">
          <li :class="0 == selectedCategoryID ? 'selected':''">
            <a href="#projects" @click="changeCategory(0)">전체보기</a>
          </li>
          <li v-for="category in categories" :key="category.id" :class="category.id == selectedCategoryID ? 'selected':''">
            <a href="#projects" @click="changeCategory(category.id)">{{category.name}}</a>
          </li>
        </ul>
        <div class="proj-menu-content">
          <div>
            <div class="proj-menu-link animate__animated animate__fadeInDown">
              <a v-for="client in currentClients" :key="client.id" :href="client.id > 0 ? '/portfolio/' + client.id : 'javascript:void(0)'">
                <span :style="{backgroundImage: 'url('+client.logoFiles[0].thumb_url+')'}"
                  @mouseenter="previewClientImage({$event, client})"
                  @mouseleave="previewClientLogo({$event, client})">
                </span>
              </a>
            </div>
            <b-pagination
              v-model="currentPage" per-page="9"
              :total-rows="clients.length"
              align="center" limit="10">
            </b-pagination>
          </div>
          <div class="proj-menu-definition animate__animated animate__fadeInRight">
            <h2>What We Did</h2>
            <div class="divider">
              <hr>
            </div>
            <div class="content animate__animated animate__fadeInRight animate_delay-2s">
              텍스트리는 LG, 삼성, 두산, 현대, 지멘스, 등과 같은 글로벌 기업이 선택한 신뢰할 수 있는 기술 컨텐츠 파트너입니다. 우리는 업계 최고가 되기 위해 계속해서 서비스를 발전시키고 우리 자신에 도전하고 있습니다.
              <br/>
              <br/>
              고객사의 즉각적이고 장기적인 요구 사항을 파악하고 비즈니스 요구 사항에 맞는 계획을 개발하기 위해 노력하여 기술문서 제작 및 관리에 대한 솔루션을 보유하고 있다는 확신을 드립니다.
            </div>
          </div>
        </div>
      </div>
      <More @next="pageMove"/>
      <MobileMore/>
      <a v-if="showScroller" id="portfolio-mobile-more" href="#projects" style="background: #008ED6" @click="showScroller = false">
        <img src="/assets/icons/mobile-arrow-more-white.png" alt="">
      </a>
    </div>
    <BgTextree/>
  </div>
</template>
<script>
import 'bootstrap/dist/css/bootstrap.css'
import store from '@/store'
import api from '@/api'
import { BPagination } from 'bootstrap-vue'

export default {
  components: {
    Navigation: () => import('@/components/nav'),
    More: () => import('@/components/more'),
    BgTextree: () => import('@/components/bg-textree'),
    MobileMore: () => import('@/components/mobile-more'),
    BPagination
  },
  data: () => ({
    selectedCategoryID: 0,
    currentPage: 1,
    showScroller: true
  }),
  computed: {
    categories: () => store.state.portfolio.categories,
    clients: function () {
      var clients = []
      for (var client of store.state.portfolio.clients) {
        if (this.selectedCategoryID == 0 || client.categories.includes(this.selectedCategoryID)) {
          clients.push(client)
        }
      }
      return clients
    },
    currentClients: function () {
      var last = this.currentPage * 9
      var start = last - 9
      var end = Math.min(this.clients.length, last)
      var clients = this.clients.slice(start, end)
      while (clients.length < 9) {
        clients.push({
          id: 0,
          logoFiles: [ { thumb_url: '/assets/images/blank.jpg' } ],
          image_thumb_url: '/assets/images/blank.jpg'
        })
      }
      return clients
    },
    pageCount: function () {
      if (this.clients.length > 9) {
        return Math.ceil(this.clients.length / 9)
      }
      return 1
    }
  },
  watch: {
    pageCount: function (pcount) {
      var pages = []
      for (var ctr = 1; ctr <= pcount; ctr++) {
        pages.push(ctr);
      }
      store.commit('SET_MORE_PAGES', pages)
    }
  },
  beforeCreate () {
    api.portfolio.getClientCategories()
    api.portfolio.getClients()
    api.portfolio.getClientSectors()
    api.portfolio.getClientIndustries()
  },
  mounted() {
    store.commit('SET_NAV_PAGE', 'portfolio')
    store.commit('SET_NAV_THEME', 'dark')
    store.commit('SET_MORE_THEME', 'dark')
    store.commit('SET_MORE_HAS_SCROLL', false)
  },
  methods: {
    changeCategory: function (category_id) {
      this.selectedCategoryID = category_id
      this.currentPage = 1
    },
    changePage: function (page) {
      this.currentPage = page
      store.commit('SET_MORE_CURRENT_INDEX', this.currentPage - 1)
    },
    prevPage: function () {
      if (this.currentPage > 1) {
        this.currentPage--
        store.commit('SET_MORE_CURRENT_INDEX', this.currentPage - 1)
      }
    },
    nextPage: function () {
      if (this.currentPage < this.pageCount) {
        this.currentPage++
        store.commit('SET_MORE_CURRENT_INDEX', this.currentPage - 1)
      }
    },
    previewClientLogo: function (e) {
      e.$event.target.style.backgroundImage = 'url('+e.client.logoFiles[0].thumb_url+')';
      e.$event.target.style.backgroundSize = '80%';
    },
    previewClientImage: function (e) {
      e.$event.target.style.backgroundImage = 'url('+e.client.image_thumb_url+')';
      e.$event.target.style.backgroundSize = 'cover';
    },
    pageMove: function (index) {
      this.currentPage = index + 1
    }
  }
}
</script>
<style scoped>
div.mobile-proj-menu-definition,
a#portfolio-mobile-more {
  display: none;
}
div#projectmenu {
  position: absolute;
  width: 100%;
  height: 100%;
}
.proj-box {
  max-width: 1600px;
  margin: 0 auto;
  margin-top: 100px;
  height: calc(100% - 100px);
  padding-left: 128px;
}

ul.proj-nav {
  display: flex;
  padding-left: 0;
  list-style-type: none;
  margin: 0;
}
ul.proj-nav li {
  border-right: 1px solid #cfcfcf;
  padding: 0 25px;
}
ul.proj-nav li:first-child {
  padding-left: 0;
}
ul.proj-nav li:last-child {
  border-right: none;
}
ul.proj-nav li a {
  color: #cfcfcf !important;
  text-decoration: none;
  list-style: none;
  font-size: 14px;
  font-weight: bold;
}
ul.proj-nav li a:hover,
ul.proj-nav li.selected a {
  color: #008ED6 !important;
}

div.proj-menu-content {
  display: flex;
}
div.proj-menu-content > div:not(.proj-menu-definition) {
  flex: 0 0 60%;
}
div.proj-menu-link {
  display: flex;
  flex-wrap: wrap;
  margin: 24px 0;
}
div.proj-menu-link a {
  flex: 0 0 calc(33%);
  height: calc(200px + 2em);
  border: 1px solid #D9D9D9;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.proj-menu-link a span {
  display: inline-block;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: 80%;
  background-repeat: no-repeat;
}

div.proj-menu-definition {
  margin-top: 24px;
  padding: 0 20px;
}
div.proj-menu-definition h2 {
  font-family: 'Montserrat';
  color: #008ED6;
  font-size: 45px;
  margin-bottom: 0;
}
div.divider {
  width: 100px;
  margin: 36px 0;
}
div.proj-menu-definition hr {
  background: #F1B500;
  height: 5px;
  border: none;
  width: 100%;
  margin-right: auto;
}
div.proj-menu-definition .content {
  font-family: 'Nanum Gothic';
  font-size: 18px;
  line-height: 2.25;
}

@media(max-width: 1440px) {
  .proj-box {
    padding-left: 64px;
  }
}
@media(max-width: 1280px) {
  .proj-box {
    padding-left: 24px;
  }
  div.proj-menu-content > div:not(.proj-menu-definition) {
    flex-basis: 70%;
  }
  div.proj-menu-definition h2 {
    font-size: 36px;
  }
  div.divider {
    margin: 24px 0;
  }
  div.proj-menu-definition .content {
    line-height: 2;
  }
}
@media(max-width: 1024px) {
  div.proj-menu-content > div:not(.proj-menu-definition) {
    flex-basis: 65%;
  }
  div.proj-menu-definition h2 {
    font-size: 24px;
  }
  div.proj-menu-definition .content {
    font-size: 16px;
    line-height: 1.8;
  }
  div.proj-menu-link a {
    height: 185px;
  }
}

@media(max-width: 768px) {
  a#portfolio-mobile-more {
    display: block;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .container-fixed {
    padding: 0;
    top: 80px;
    bottom: 64px;
    height: calc(100% - 64px - 80px);
    overflow-y: scroll;
  }
  div.mobile-proj-menu-definition {
    display: block;
  }
  ul.b-pagination {
    display: none;
  }
  .proj-box {
    margin-top: 24px;
    width: 100%;
    height: auto;
    padding: 0 24px;
  }
  .proj-box .proj-menu-definition {
    display: none;
  }
  ul.proj-nav {
    flex-wrap: wrap;
    margin: 0 -6px;
  }
  ul.proj-nav li {
    flex: 0 0 calc(50% - 12px);
    border: 1px solid #CFCFCF !important;
    text-align: center;
    padding: 6px 0;
    margin: 6px;
  }
  ul.proj-nav li a {
    display: block;
  }
  div.proj-menu-content > div:not(.proj-menu-definition) {
    flex-basis: 100%;
  }
  div.proj-menu-link a {
    flex-basis: calc(100% / 3);
    height: 128px;
  }
}

@media(max-width: 425px) {
  div.proj-menu-definition h2 {
    font-size: 35px;
  }
  div.proj-menu-definition .content {
    font-family: 'Nanum Gothic';
    font-size: 12px;
    line-height: 25px;
  }
}

</style>

<style>
@media(max-width: 1024px) {
  #plusfriend-chat-button {
    bottom: 12px;
  }
}

@media(max-width: 768px) {
  #plusfriend-chat-button {
    bottom: 64px;
  }
}
</style>
